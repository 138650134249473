<template>
	<div>
		<div class="flex justify-between">
			<template v-if="filterType === 'multiple'">
				<Filters v-model:query="computedQuery" v-model:currentFilterValues="computedCurrentFilterValues" :filters="filters" />
			</template>
			<template v-else>
				<div v-if="filterType === 'tabs'">
					<template v-for="(filter, key) in filters" :key="key">
						<div class="sm:hidden">
							<label for="tabs" class="sr-only">Select a tab</label>
							<select id="tabs" name="tabs" class="block w-full focus:ring-jmi-purple3 focus:border-jmi-purple3 border-jmi-purple4 rounded-md" @input="setFilter(key, $event.target.value)">
								<option v-for="tab in filter.options" :key="tab.id" :value="tab.id" :selected="(currentFilterValues[key] === tab.id)">
									{{ tab.label }}
								</option>
							</select>
						</div>
						<div class="hidden sm:block">
							<nav class="flex space-x-2" :dusk="'filter-'+key" aria-label="Tabs">
								<button
									v-for="tab in filter.options"
									:key="tab.id"
									:dusk="'filter-'+key+'-'+slugify(tab.id)"
									type="button"
									:value="tab.id"
									:class="[(currentFilterValues[key] === tab.id) ? 'bg-jmi-purple2 text-jmi-white' : 'bg-jmi-purple4 text-jmi-purple2 hover:text-jmi-purple1', 'px-4 py-2 text-sm rounded-full']"
									:aria-current="(currentFilterValues[key] === tab.id) ? 'page' : undefined"
									@click.prevent="setFilter(key, tab.id)"
								>
									{{ tab.label }}
								</button>
							</nav>
						</div>
					</template>
				</div>
				<div v-else-if="filterType === 'dropdown'">
					<template v-for="(filter, key) in filters" :key="key">
						<label for="tabs" class="sr-only">Select a tab</label>
						<select id="tabs" name="tabs" class="block w-full focus:ring-jmi-purple3 focus:border-jmi-purple3 border-jmi-purple4 rounded-md" @input="setFilter(key, $event.target.value)">
							<option v-for="tab in filter.options" :key="tab.id" :value="tab.id" :selected="(currentFilterValues[key] === tab.id)">
								{{ tab.label }}
							</option>
						</select>
					</template>
				</div>
				<div v-else>
					<slot name="filters" />
				</div>

				<div v-if="useSearch">
					<FormsInput v-model="computedQuery" dusk="table-query" class="pl-10" input-class="pl-9">
						<template #prefix>
							<div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
								<MagnifyingGlassIcon class="h-5 w-5 text-jmi-purple3" aria-hidden="true" />
							</div>
						</template>
					</FormsInput>
				</div>
			</template>
		</div>

		<!-- In case the user is searching or have any filter and we have 0 record we display a graceful message -->
		<div v-if="hasCompletedFirstSearch && !isLoading && records.length === 0">
			<slot name="empty-default">
				<div class="w-full my-56 mx-auto block">
					<NotAllowed
						title="No results found"
					/>
				</div>
			</slot>
		</div>
		<div v-else class="bg-white shadow rounded-md mt-5">
			<table v-if="useTableElement" class="min-w-full divide-y divide-jmi-purple3">
				<thead v-if="computedHeaders.length > 0" class="bg-jmi-white">
				<tr>
					<th v-for="header in computedHeaders" :key="header.id" scope="col" class="p-4 whitespace-nowrap text-left text-sm font-medium text-jmi-purple1">
						<a v-if="header.sortable" href="#" class="group inline-flex" @click.prevent="sort(header.id)">
							{{ header.label }}
							<span :class="getSortClasses(header.id)">
									<ChevronUpIcon v-if="sortBy === header.id && sortOrder === 'asc'" class="h-5 w-5 stroke-1" aria-hidden="true" />
									<ChevronDownIcon v-else class="h-5 w-5 stroke-1" aria-hidden="true" />
								</span>
						</a>
						<template v-else>
							{{ header.label }}
						</template>
					</th>
					<th scope="col" class="relative py-3.5 pr-4">
						<span class="sr-only">Edit</span>
					</th>
				</tr>
				</thead>
				<tbody v-if="!isLoading" class="divide-y divide-jmi-purple4 bg-white text-sm">
				<slot />
				</tbody>
				<tbody v-else class="divide-y divide-jmi-purple4 bg-white text-sm">
				<tr>
					<td :colspan="computedHeaders.length" class="p-20 text-center text-jmi-purple2 group-hover:text-jmi-purple1">
						<svg class="animate-spin mx-auto h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
							<circle
								class="opacity-25"
								cx="12"
								cy="12"
								r="10"
								stroke="currentColor"
								stroke-width="4"
							/>
							<path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
						</svg>
					</td>
				</tr>
				</tbody>
			</table>
			<div v-else :class="containerClass">
				<template v-if="!isLoading">
					<slot />
				</template>
				<div v-else class="divide-y divide-jmi-purple4 bg-white text-sm">
					<div class="p-20 text-center text-jmi-purple2 group-hover:text-jmi-purple1">
						<svg class="animate-spin mx-auto h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
							<circle
								class="opacity-25"
								cx="12"
								cy="12"
								r="10"
								stroke="currentColor"
								stroke-width="4"
							/>
							<path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
						</svg>
					</div>
				</div>
			</div>
		</div>

		<template v-if="count && usePagination">
			<Pagination
				:start="pageStart"
				:end="pageEnd"
				:total="count"
				:has-next="hasNextPage"
				:has-previous="hasPreviousPage"
				@previous="$emit('previous')"
				@next="$emit('next')"
			/>
		</template>
	</div>
</template>
<script setup lang="ts">
import {
	ChevronDownIcon,
	ChevronUpIcon,
	MagnifyingGlassIcon,
} from '@heroicons/vue/24/outline';
import {computed, PropType, watch, ref} from "vue";
import type {TableFilters, TableHeaders} from "~/utils/types";
import slugify from "~/utils/slugify";

const props = defineProps({
	useTableElement: {
		type: Boolean,
		required: false,
		default: true
	},
	containerClass: {
		type: String,
		required: false,
		default: ""
	},
	currentFilterValues: {
		type: Object,
		required: false,
		default: () => ({}),
	},
	filters: {
		type: Object as PropType<TableFilters<any>>,
		required: false,
		default: () => ({}),
	},
	headers: {
		type: Array as PropType<TableHeaders>,
		required: false,
		default: () => ([])
	},
	records: {
		type: Array,
		required: true
	},
	query: {
		type: String,
		required: false,
		default: ""
	},
	count: {
		type: Number,
		required: false,
		default: 0
	},
	perPage: {
		type: Number,
		required: false,
		default: 10
	},
	currentPage: {
		type: Number,
		required: false,
		default: 1
	},
	currentSort: {
		type: Object,
		required: false,
	},
	hasNextPage: {
		type: Boolean,
		required: true
	},
	isLoading: {
		type: Boolean,
		required: false,
		default: false
	},
	hasCompletedFirstSearch: {
		type: Boolean,
		required: false,
		default: false
	},
	useSearch: {
		type: Boolean,
		required: false,
		default: true
	},
	usePagination: {
		type: Boolean,
		required: false,
		default: true
	},
});

const emit = defineEmits(['update:query', 'update:currentFilterValues', 'sort', 'previous', 'next']);

const computedQuery = computed({
	get() {
		return props.query;
	},
	set(value) {
		emit('update:query', value);
	}
});

const computedHeaders = computed(() => {
	return props.headers.map((header) => {
		header.sortable = header.sortable ?? true;
		return header;
	});
});

const filterType = computed(() => {
	if (props.filters) {
		const keys = Object.keys(props.filters);
		const length = keys.length;

		if (length === 1) {
			if (props.filters[keys[0]]?.type === 'date-range') {
				return "multiple";
			} else {
				if (props.filters[keys[0]]?.options?.length > 5) {
					return "dropdown";
				} else {
					return "tabs";
				}
			}
		} else if (length > 1) {
			return "multiple";
		}
	}

	return "slot";
});

const computedPerPage = computed(() => {
	return props.perPage ?? props.records.length;
});

const sortBy = computed(() => {
	return props.currentSort ? Object.keys(props.currentSort)[0] : ''
})
const sortOrder = computed(() => {
	return props.currentSort ? props.currentSort[Object.keys(props.currentSort)[0]] : 'asc'
})

const pageStart = computed(() => {
	return (computedPerPage.value * (props.currentPage - 1)) + 1;
});

const pageEnd = computed(() => {
	let end = computedPerPage.value * props.currentPage;
	return end > (props.count ?? 0) ? props.count : end;
});

const hasPreviousPage = computed(() => {
	return props.currentPage > 1;
});

const computedCurrentFilterValues = computed({
	get() {
		return props.currentFilterValues;
	},
	set(newFilterValues) {
		emit('update:currentFilterValues', newFilterValues);
	}
});

const setFilter = (key: string, value: unknown) => {
	let newFilterValues = {...props.currentFilterValues};
	newFilterValues[key] = value;
	emit('update:currentFilterValues', newFilterValues);
};

const getSortClasses = (field: string) => {
	let classes = "ml-2 flex-none text-jmi-purple3 group-hover:visible group-focus:visible";

	if (field !== sortBy.value) {
		classes += " invisible text-jmi-purple3";
	} else {
		classes += " text-jmi-purple2";
	}

	return classes;
};

const sort = (field: string) => {
	let newSortOrder;

	if (field === sortBy.value) {
		newSortOrder = sortOrder.value === 'desc' ? 'asc' : 'desc';
	} else {
		newSortOrder = 'asc';
	}

	emit('sort', [field, newSortOrder]);
};
let hasFilters = ref(false);
watch(computedCurrentFilterValues, (newFilterValues) => {
	// Loop all the filter keys and if the length of the filter value > 0 return true
	hasFilters.value = Object.keys(newFilterValues).some((key) => {
		return newFilterValues[key];
	});
});

</script>
